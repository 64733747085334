import React, { FC } from 'react';
import classNames from 'classnames';
import { useScreenService } from 'hooks/useScreenService';

import { Container } from 'layout';
import Typography from 'common/Typography';
import ArticleCard from 'components/ArticleCard';
import Slider from 'components/Slider';

import { LatestArticlesProps } from './models';

import './LatestArticles.scss';

const LatestArticles: FC<LatestArticlesProps> = ({
  sectionTitle,
  articles,
  isDesktopList,
  isWhiteBg,
}) => {
  const latestArticlesClassnames = classNames('latest-articles', {
    'latest-articles--white-bg': isWhiteBg,
  });

  const { isMobile } = useScreenService();

  const renderArticles = articles?.map(
    ({ educationImage, title, introduction, button }: CommonTypes.ArticleCardType) => (
      <li key={title} className="latest-articles__item">
        <ArticleCard
          image={educationImage}
          title={title}
          description={introduction}
          button={button}
        />
      </li>
    )
  );

  const SINGLE_ARTICLE = renderArticles.length === 1;

  const renderSlider = (
    <Container fluid noPadding element="section">
      <Typography
        variant="heading2"
        weight="medium"
        align="center"
        customClass="latest-articles__title"
      >
        {sectionTitle}
      </Typography>
      <Container leftPadding={isMobile}>
        <Slider
          isCustomNavigation
          isCustomPagination={isMobile}
          pagination={{
            el: '.swiper-pagination-custom-bullets',
            type: 'bullets',
            clickable: true,
          }}
          slides={renderArticles}
          breakpoints={{
            320: {
              slidesPerView: 1.05,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
          loop={isMobile && !SINGLE_ARTICLE}
          autoplay={false}
        />
      </Container>
    </Container>
  );

  const renderDesktopList = !isMobile ? (
    <Container element="section" className="latest-articles__wrapper">
      <Typography
        variant="heading2"
        weight="medium"
        align="center"
        customClass="latest-articles__title"
      >
        {sectionTitle}
      </Typography>
      <ul className="latest-articles__list">{renderArticles}</ul>
    </Container>
  ) : (
    renderSlider
  );

  const renderLatestArticles = isDesktopList ? renderDesktopList : renderSlider;

  return (
    <Container fluid noPadding className={latestArticlesClassnames}>
      {renderLatestArticles}
    </Container>
  );
};

export default LatestArticles;
