import React, { FC } from 'react';

import Image from 'common/Image';

import { BugSlideProps } from './models';

import './BugSlide.scss';

const BugSlide: FC<BugSlideProps> = ({
  isSlideActive,
  imageData: bugImageData,
  alt,
  className,
  tags,
}) => {
  const renderDialogBox = (isActive: boolean, imageData) => {
    if (!isActive) return null;

    localStorage.setItem('activeBug', tags.name);

    return <div className="swiper-image--active__dialog-box">{imageData.altText}</div>;
  };

  return (
    <>
      <Image imageData={bugImageData} alt={alt} className={className} />
      {renderDialogBox(isSlideActive, bugImageData)}
    </>
  );
};

export default BugSlide;
