import React, { FC } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { breakpoints as breakpointsValues } from 'shared/static';
import SwiperCore, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Icon from 'common/Icon';
import { defaultOptions, setDefaultBreakpoints } from 'utils/slider';

import { SliderProps } from './models';

import './Slider.scss';

import 'swiper/css/navigation';
import 'swiper/css';

const Slider: FC<SliderProps> = ({
  slides,
  slidesPerView,
  isCustomPagination,
  isCustomNavigation,
  isMobileSingleSlide,
  breakpoints,
  autoplay,
  customClass,
  ...rest
}) => {
  const swiperClassnames = classNames('swiper-container', customClass);

  SwiperCore.use([Navigation, Pagination, Autoplay]);

  const swiperParams: SwiperOptions = {
    slidesPerView: isMobileSingleSlide ? defaultOptions.slidesPerView : slidesPerView,
    pagination: defaultOptions.pagination,
    breakpoints: setDefaultBreakpoints({
      breakpoints,
      breakpointValue: breakpointsValues.md,
      slidesPerView,
      isMobileSingleSlide,
    }),
    autoplay: autoplay && defaultOptions.autoplay,
    ...rest,
  };

  const slidesNodes = slides?.map(
    (slide: React.ReactNode, index: number): JSX.Element => (
      // eslint-disable-next-line react/no-array-index-key
      <SwiperSlide key={index}>{slide}</SwiperSlide>
    )
  );

  const renderNavigation = isCustomNavigation ? (
    <>
      <Icon className="swiper-button-prev" icon={faChevronLeft} />
      <Icon className="swiper-button-next" icon={faChevronRight} />
    </>
  ) : null;

  const renderPagination =
    isCustomPagination && slides && slides?.length > 1 ? (
      <div className="swiper-pagination-custom-bullets" />
    ) : null;

  const renderSwiper = isCustomPagination ? (
    <div className="swiper-box">
      <Swiper
        tag="section"
        data-testid="swiper-slider"
        className={swiperClassnames}
        {...swiperParams}
      >
        {slidesNodes}
      </Swiper>
      {renderNavigation}
      {renderPagination}
    </div>
  ) : (
    <Swiper
      tag="section"
      data-testid="swiper-slider"
      className={swiperClassnames}
      {...swiperParams}
    >
      {slidesNodes}
    </Swiper>
  );

  return renderSwiper;
};

export default Slider;
