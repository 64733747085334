import React, { FC, useEffect, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Image from 'common/Image';
import BannerContentWrapper from 'components/BannerContentWrapper';
import { PURPOSE } from 'components/BannerContentWrapper/models.d';
import { BUTTON } from 'components/Button';
import CarouselArrow from 'components/CarouselArrow';

import { HeroBannerProps } from './models';

import './HeroBanner.scss';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const swiperOptions: SwiperOptions = {
  modules: [Navigation, Pagination],
  loop: true,
  navigation: { prevEl: '.arrow-left', nextEl: '.arrow-right' },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
};

const HeroBanner: FC<HeroBannerProps> = ({ heroCarousel, carouselButtons }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const renderCarousel = (
    heroCarouselType: CommonTypes.HeroBannerType[],
    buttons: HomePageTypes.CarouselButtonsType
  ) => {
    const { prevBtnAria, nextBtnAria } = buttons!;

    return (
      <Swiper className="hero-banner" {...swiperOptions}>
        <CarouselArrow
          ariaLabel={prevBtnAria}
          icon={faChevronLeft}
          className="arrow arrow-left hide--on-mobile"
          variant={BUTTON.VARIANT.ARROW_CONTAINED}
        />
        {heroCarouselType.map(
          ({
            heroBannerButton,
            heroBannerDescription,
            heroBannerMainImage,
            heroBannerMobile,
            heroBannerTitle,
          }: CommonTypes.HeroBannerType) => (
            <SwiperSlide key={heroBannerMainImage.altText} className="banner-slide">
              <Image
                imageData={heroBannerMainImage}
                alt={heroBannerMainImage.altText}
                className="banner-slide__image hide--on-mobile"
              />
              <Image
                imageData={heroBannerMobile}
                alt={heroBannerMobile.altText}
                className="banner-slide__image hide--on-desktop"
              />
              <BannerContentWrapper
                title={heroBannerTitle}
                description={heroBannerDescription}
                button={heroBannerButton}
                customClass="banner-slide__content"
                purpose={PURPOSE.HERO_BANNER}
                headingVariant="heading7"
                descriptionVariant="paragraph4"
              />
            </SwiperSlide>
          )
        )}
        <div className="swiper-pagination" />
        <CarouselArrow
          ariaLabel={nextBtnAria}
          icon={faChevronRight}
          className="arrow arrow-right hide--on-mobile"
          variant={BUTTON.VARIANT.ARROW_CONTAINED}
        />
      </Swiper>
    );
  };

  return (
    <>
      {carouselButtons
        ? renderCarousel(loaded ? heroCarousel : heroCarousel.slice(0, 1), carouselButtons)
        : null}
    </>
  );
};

export default HeroBanner;
