import { SwiperOptions } from 'swiper';

import { SetDefaultBreakpointsParams } from './models';

const defaultDelayTime = 5000;
const defaultSingleView = 1;

export const defaultOptions: SwiperOptions = {
  slidesPerView: defaultSingleView,
  pagination: { clickable: true },
  autoplay: {
    delay: defaultDelayTime,
    disableOnInteraction: false,
  },
};

export const setDefaultBreakpoints = ({
  breakpoints,
  breakpointValue,
  isMobileSingleSlide,
  slidesPerView,
}: SetDefaultBreakpointsParams) =>
  breakpoints !== undefined || isMobileSingleSlide === false
    ? breakpoints
    : {
        [breakpointValue]: {
          slidesPerView: slidesPerView || defaultOptions.slidesPerView,
        },
      };
