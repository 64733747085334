import React, { FC } from 'react';
import { graphql } from 'gatsby';
import HeroBanner from 'containers/HeroBanner';
import LatestArticles from 'containers/LatestArticles';
import TipCards from 'containers/TipCards';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import BugCarousel from 'components/BugCarousel';

import 'styles/main.scss';
import './HomePage.scss';

const HomePage: FC<{ data: HomePageTypes.HomePageSiteTypes }> = ({
  data: {
    homepage: {
      pageName,
      urls,
      seo,
      cardsTitle,
      tipCards,
      carouselButtons,
      allProductsButton,
      educationButton,
      homePageCarousel,
      insectsSectionTitle,
      relatedArticlesTitle,
    },
    solutions,
    allInsectDetails,
    allArticleDetails: { nodes: articles },
  },
}) => {
  const { title, keywords, description } = seo;

  const { categoryName: aproachFilters } = solutions.productCategories[1];

  const orderedInsects = allInsectDetails.nodes.sort((a, b) =>
    a.orderNumber > b.orderNumber ? 1 : -1
  );

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo, pageName }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <HeroBanner heroCarousel={homePageCarousel} carouselButtons={carouselButtons} />
      <BugCarousel
        nodes={orderedInsects}
        carouselButtons={carouselButtons}
        insectsSectionTitle={insectsSectionTitle}
        allProductsButton={allProductsButton}
        educationButton={educationButton}
        aproachFilters={aproachFilters}
      />
      <TipCards cardsTitle={cardsTitle} cards={tipCards} customClass="homepage__tip-cards" />
      <LatestArticles
        isWhiteBg
        isDesktopList
        sectionTitle={relatedArticlesTitle}
        articles={articles}
      />
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery($lang: String, $relatedArticlesLinks: [String]) {
    homepage(lang: { eq: $lang }) {
      ...HomePageFragment
    }
    solutions {
      productCategories {
        ...ProductCategoriesFragment
      }
    }
    allInsectDetails {
      nodes {
        ...InsectDetailFragment
      }
    }
    allArticleDetails(filter: { lang: { eq: $lang }, url: { in: $relatedArticlesLinks } }) {
      nodes {
        ...ArticleCardFragment
      }
    }
  }
`;

export default HomePage;
