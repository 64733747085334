import React, { FC, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { PEST_QUERY_TAG } from 'shared/constants';
import { EffectCoverflow, Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Typography from 'common/Typography';
import BugSlide from 'components/BugSlide';
import Button, { BUTTON } from 'components/Button';
import CarouselArrow from 'components/CarouselArrow';

import { BugCarouselProps } from './models';

import './BugCarousel.scss';

import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';
import 'swiper/css/zoom';
import 'swiper/css';

const BugCarousel: FC<BugCarouselProps> = ({
  nodes,
  carouselButtons,
  insectsSectionTitle,
  allProductsButton,
  educationButton,
}) => {
  const [activeBug, setActiveBug] = useState<string | null>();

  const desktopSwiperOptions: SwiperOptions = {
    modules: [Navigation, EffectCoverflow],
    navigation: { prevEl: '.prevArrow', nextEl: '.nextArrow' },
    loop: true,
    loopAdditionalSlides: 30,
    centeredSlides: true,
    slidesPerView: 5,
    touchEventsTarget: 'container',
    grabCursor: true,
    slideToClickedSlide: true,
    preventInteractionOnTransition: true,
    touchReleaseOnEdges: true,
    effect: 'coverflow',
    coverflowEffect: {
      scale: 1,
      slideShadows: false,
      depth: 0,
      modifier: 0,
    },
  };

  const mobileSwiperOptions: SwiperOptions = {
    modules: [Navigation, EffectCoverflow],
    navigation: { prevEl: '.prevArrow', nextEl: '.nextArrow' },
    loop: true,
    centeredSlides: true,
    slidesPerView: 2.1,
    grabCursor: true,
    slideToClickedSlide: true,
    preventInteractionOnTransition: true,
    touchReleaseOnEdges: true,
    breakpoints: {
      470: {
        slidesPerView: 3,
      },
    },
  };

  const { nextBtnAria, prevBtnAria } = carouselButtons!;

  const {
    ariaLabel: allProductsAria,
    buttonText: allProductsButtonText,
    buttonLink: { url: allProductsUrl },
  } = allProductsButton!;

  const { ariaLabel: educationAria, buttonText: educationText } = educationButton!;

  const setSolutionFilterUrl = () => {
    const url = `${allProductsUrl}?${PEST_QUERY_TAG}=${activeBug}`;

    return url;
  };

  setSolutionFilterUrl();
  const renderBottomButtons = (
    <div className="bug-carousel__buttons">
      <Button
        variant={BUTTON.VARIANT.OUTLINE}
        icon
        link={allProductsUrl}
        ariaLabel={allProductsAria}
      >
        {allProductsButtonText}
      </Button>
      <Button
        variant={BUTTON.VARIANT.OUTLINE}
        icon
        link={setSolutionFilterUrl()}
        ariaLabel={educationAria}
      >
        {educationText}
      </Button>
    </div>
  );

  const renderSlides = () =>
    nodes.map((node) => (
      <SwiperSlide key={node.insectImage.altText}>
        {({ isActive }) => {
          const imageClassNames = classNames('swiper-image', {
            'swiper-image--active': isActive,
          });

          return (
            <BugSlide
              isSlideActive={isActive}
              className={imageClassNames}
              imageData={node.insectImage}
              alt={node.insectImage.altText}
              tags={node.tags[1]}
            />
          );
        }}
      </SwiperSlide>
    ));

  const handleSlideChange = () => {
    setTimeout(() => {
      const currentBug = localStorage.getItem('activeBug');
      setActiveBug(currentBug);
    }, 0);
  };

  const renderInsectSwiper = (
    <div className="bug-carousel__wrapper">
      <CarouselArrow
        ariaLabel={prevBtnAria}
        icon={faChevronLeft}
        className="arrow prevArrow hide--on-mobile"
        variant={BUTTON.VARIANT.ARROW}
      />
      <Swiper
        className="hide--on-mobile"
        {...desktopSwiperOptions}
        onSlideChange={handleSlideChange}
      >
        {renderSlides()}
      </Swiper>
      <Swiper
        className="hide--on-desktop"
        {...mobileSwiperOptions}
        onSlideChange={handleSlideChange}
      >
        {renderSlides()}
      </Swiper>
      <CarouselArrow
        ariaLabel={nextBtnAria}
        icon={faChevronRight}
        className="arrow nextArrow hide--on-mobile"
        variant={BUTTON.VARIANT.ARROW}
      />
    </div>
  );

  return (
    <div className="bug-carousel">
      <Typography variant="heading2" weight="medium" align="center">
        {insectsSectionTitle}
      </Typography>
      {renderInsectSwiper}
      {renderBottomButtons}
    </div>
  );
};

export default BugCarousel;
